+(($) ->
  burgerToggle = $('nav .burger-toggle')
  burger = $('nav .burger')
  overlay = $('nav .overlay')
  sidemenu = $('nav .sidemenu')

  burgerToggle.on 'click', ->
    burger.toggleClass 'is-active'
    overlay.toggleClass 'is-active'
    sidemenu.toggleClass 'is-active'

  overlay.on 'click', ->
    burger.removeClass 'is-active'
    overlay.removeClass 'is-active'
    sidemenu.removeClass 'is-active'

)(jQuery)
